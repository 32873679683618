<script>
import { mapState } from 'pinia'
import { usePageStore } from '@voix/store/pageStore'
import { useNavigationStore } from '../../store/navigationStore'

export default {
  props: {
    currentResort: {
      type: Object,
      default: () => {},
    },
    footerCompanyMenu: {
      type: Object,
      required: true,
    },
    footerResourcesMenu: {
      type: Object,
      required: true,
    },
    footerTermsMenu: {
      type: Object,
      required: false,
    },
    bgType: {
      type: String,
      default: 'dark',
    },
    config: {
      type: Object,
      default: () => ({
        crossPromotion: true,
        signUpForm: false,
        termsMenuPosition: 'left',
      }),
    },
  },

  data() {
    return {
      isVisible: false,
    }
  },

  computed: {
    ...mapState(useNavigationStore, ['brands']),

    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },

    footerSettings() {
      return this.currentResort?.footer_settings
    },

    backgroundHeart() {
      if (this.bgType === 'white')
        return { backgroundImage: 'url(https://playa-media.s3.us-east-2.amazonaws.com/uploads/playa_heart_white.png)' }

      return { backgroundImage: 'url(https://playa-media.s3.us-east-2.amazonaws.com/uploads/playa_heart.png)' }
    },
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div v-if="footerSettings" id="footer" name="footer">
    <!-- TODO: use hydration method rather than visibility directive -->
    <span v-voix-visibility="{ callback: visibilityChanged, threshold: [0, .25] }" />
    <div
      class="top flex justify-center bg-bottom bg-left bg-no-repeat"

      :style="{
        ...backgroundHeart,
        'background-color': footerSettings.top.background,
        'color': footerSettings.top.foreground,
      }"
    >
      <div class="pt-9 px-8 md:px-12 text-center min-h-[250px]">
        <div
          class="relative z-10 inline-block mx-4 p-8 rounded-full"
          :style="{ 'background-color': footerSettings.top.background }"
        >
          <LogosPlaya :fill="false" class="w-[120px]" />
        </div>

        <div
          v-if="isVisible"
          class="relative flex flex-row justify-center space-x-6 flex-wrap border rounded-2xl -mt-16 mb-4 pt-10 pb-6 px-4 border-white"
          :style="{ 'border-color': footerSettings.top.foreground }"
        >
          <a
            href="https://www.resortsbyhyatt.com/"
            aria-label="Hyatt Ziva"
            class="pt-4 my-4 md:my-0"
          >
            <LazyLogosHyattZivaZilara
              :fill="false"
              style="height: 50px"
            />
          </a>

          <a
            href="https://hiltonbyplaya.com/"
            aria-label="Resorts By Hilton"
            class="pt-2 my-4 md:my-0"
          >
            <LazyLogosHilton :fill="false" style="height: 65px" />
          </a>

          <a
            href="https://alltrabyplaya.com/"
            aria-label="Wyndham Alltra"
          >
            <LazyLogosWyndham class="w-48" style="height: 86px" />
          </a>

          <a
            href="https://sanctuarycapcana.com/"
            aria-label="Sanctuary Capcana"
            class="pt-6 my-4 md:my-0"
          >
            <LazyLogosMarriotBonvoy
              :fill="false"
              style="height: 30px"
            />
          </a>

          <a
            href="https://www.hilton.com/en/brands/tapestry-collection/"
            aria-label="Tapesty Collection by Hilton"
            class="pt-4 my-4 md:my-0"
          >
            <LazyLogosTapestryCollection
              :fill="false"
              style="height: 48px"
            />
          </a>

          <a
            href="https://www.seadustcancun.com/en"
            aria-label="Seadust Cancun Family Resort"
            class="pt-1 my-4 md:my-0"
          >
            <LazyLogosSeadust
              :fill="false"
              class="-mx-8"
              style="height: 60px"
            />
          </a>

          <a
            href="https://www.ihg.com/"
            aria-label="IHG Hotel & Resorts"
            class="pt-1 my-4 md:my-0"
          >
            <LazyLogosIHG
              :fill="false"
            />
          </a>
        </div>

        <ul class="uppercase mt-0 md:mt-4 font-light text-md flex justify-center flex-col md:flex-row text-center items-center  p-4">
          <li class="border-0 md:border-1 md:border-r border-white px-4">
            Mexico
          </li>
          <li class="border-0 md:border-1 md:border-r border-white px-4">
            Jamaica
          </li>
          <li class="border-0 border-white px-4">
            Dominican Republic
          </li>
        </ul>

        <ul
          v-if="footerCompanyMenu"
          class="flex justify-center flex-col md:flex-row text-center items-center p-4 md:divide-x divide-white/25"
        >
          <li class="md:mr-4 mb-2">
            <div
              v-cloak
              class="text-lg uppercase"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ $t('the-company') }} :
            </div>
          </li>
          <li
            v-for="item in footerCompanyMenu.menu_items"
            :key="item.id"
            class="border-0 px-4"
          >
            <a
              v-cloak
              class="text-sm"
              :href="item.permalink"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>

        <ul
          v-if="footerResourcesMenu"
          class="flex justify-center flex-col md:flex-row text-center items-center p-4  md:divide-x divide-white/25"
        >
          <li class="md:mr-4 mb-2">
            <div
              v-cloak
              class="text-lg uppercase"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ $t('resources') }} :
            </div>
          </li>
          <li
            v-for="item in footerResourcesMenu.menu_items"
            :key="item.id"
            class="px-4"
          >
            <a
              v-cloak
              class="text-sm"
              :href="item.permalink"
              :style="{ color: footerSettings.top.foreground }"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>

        <div
          class="flex justify-center flex-col md:flex-row text-center items-center p-4"
        >
          <div
            v-cloak
            class="mb-4 md:mb-0 font-light"
            :style="{ color: footerSettings.top.foreground }"
          >
            {{ $t('follow-us') }}
          </div>

          <ul v-if="isVisible" class="flex justify-center text-center items-center list-reset">
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.facebook.com/PlayaResorts/"
                aria-label="Playa Resorts Facebook"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsFacebook :w="25" :h="25" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://twitter.com/playaresorts"
                aria-label="Playa Resorts Twitter"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsTwitter :w="25" :h="25" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.instagram.com/playaresorts/"
                aria-label="Playa Resorts Instagram"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsInstagram :w="25" :h="25" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.youtube.com/channel/UCEHoZZoXR4lh-Mas2FYx-nQ"
                aria-label="Playa Resorts YouTube"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsYouTube :w="25" :h="25" />
              </a>
            </li>
            <li class="mx-1">
              <a
                class="text-sm"
                href="https://www.pinterest.com/PlayaResorts/"
                aria-label="Playa Resorts inset-0terest"
                target="_blank"
                :style="{ color: footerSettings.top.foreground }"
              >
                <LazyIconsPinterest :w="25" :h="25" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="bottom"
      :style="{
        'background-color': footerSettings.bottom.background,
        'color': footerSettings.bottom.foreground,
      }"
    />

    <div
      v-if="config.signUpForm"
      class="bottom"
      :style="{ backgroundColor: footerSettings.bottom.background, color: footerSettings.bottom.foreground }"
    >
      <SignUpForm
        form-id="A01904B0-3D23-4A65-B86A-EECBC4BD7A70"
        :language="currentPage?.language_code"
      />
    </div>
    <div class="bg-white">
      <div
        class="flex flex-col md:flex-row justify-center md:justify-between p-4 text-xs"
      >
        <div>
          <ul
            v-if="footerTermsMenu"
            class="flex flex-col md:flex-row items-center list-reset"
          >
            <li v-cloak class="my-2 md:my-0 mx-2">
              <a class="text-xs text-grey-darker" :href="`https://www.playaresorts.com${$t('path')}terms-conditions`">
                {{ $t('terms-and-conditions') }}
              </a>
            </li>
            <li v-cloak class="my-2 md:my-0 mx-2">
              <a class="text-xs text-grey-darker" :href="`https://www.playaresorts.com${$t('path')}privacy-policy`">
                {{ $t('privacy-policy') }}
              </a>
            </li>
            <li v-cloak class="my-2 md:my-0 mx-2">
              <a class="text-xs text-grey-darker" href="https://www.playaresorts.com/accessibility">
                {{ $t('accessibility') }}
              </a>
            </li>
            <li v-cloak class="my-2 md:my-0 mx-2">
              <a
                id="ot-sdk-btn" class="text-xs text-grey-darker ot-sdk-show-settings p-0"
                href="javascript:void(0)"
              >
                {{ $t('cookie-settings') }}
              </a>
            </li>
            <li v-cloak class="my-2 md:my-0 mx-2">
              <a class="text-xs text-grey-darker" href="https://agentcashplus.com/">
                {{ $t('travel-professionals') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="text-center md:text-right mt-8 md:mt-0">
          &copy; {{ new Date().getFullYear() }} Playa Management USA, LLC All
          rights reserved
        </div>
      </div>
      <div v-if="currentPage && currentPage.site_id === 1" class="text-center bg-gray-200 text-2xs p-2">
        {{ $t('playa-nv-disclaimer') }}
      </div>
    </div>
  </div>
</template>
