<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Hyatt Zilara Layout',
  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },
  colorPalette: {
    '#1d3091': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#1e1b57': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#672c91': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#d23476': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/hyatt-zilara/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/hyatt-zilara/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/hyatt-zilara/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/hyatt-zilara/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022', links)

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#1e1b57' })

if (currentResort?.value)
  provide('currentResort', currentResort.value.data)

if (resorts?.value)
  provide('resorts', resorts.value.data)

if (brands?.value)
  provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white sanctuary-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              logoPosition: 'left', // left or center
              menuPosition: 'right', // right or center
              backgroundClass: 'bg-white',
              bookNowBackground: 'bg-[#1e1b57]',
              bookNowText: 'text-white',
              languageSelectorBackground: 'bg-[#1e1b57]',
              languageSelectorText: 'text-white',
              paper: false,
              hyattAnimationBlue: true,
              relativeLogo: true,
              darkTopBar: true,
              logoWidth: 200,
            }"
          />
          <slot />

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :footer-company-menu="footerCompanyMenu?.data"
            :footer-resources-menu="footerResourcesMenu?.data"
            :config="{
              crossPromotion: false,
              signUpForm: true,
              socialMenu: true,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/hyatt-zilara.scss';
</style>
