<script setup lang="ts">
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

defineProps<{
  currentResort: {
    type: object
  }
}>()

const bookingWidgetStore = useBookingWidgetStore()
function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}

const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)

const placeholderMenu = [
  'Rooms',
  'Activities',
  'Dining',
  'Spa',
  'Weddings',
  'Special Offers',

]
</script>

<template>
  <div class="bg-bonita-primary text-bonita-inverse pb-8 text-xs relative">
    <div class="flex flex-row justify-center items-center w-full py-8 bg-white">
      <LazyLogosMarriotBonvoy
        :fill="true"
        class="h-12"
      />
    </div>

    <div class="max-w-[1200px] mx-auto grid grid-cols-12 gap-8 sm:gap-0 lg:gap-16 pt-8">
      <div class="col-span-12 sm:col-span-6 flex space-x-8 sm:space-x-4 px-8">
        <div class="border-r border-bonita-inverse pr-4 flex items-center">
          <LogosBonita class="w-full h-full text-bonita-inverse" />
        </div>

        <div class="min-w-28 flex flex-col justify-center items-start space-y-2">
          <button class="font-bold mb-2 hover:underline" @click="toggleBookingModal">
            {{ $t("book-now") }}
          </button>
          {{ currentResort.full_address }}
          <a :href="`tel:${currentResort.phone_number}`" class="block text-bonita-inverse">{{ currentResort.phone_number }}</a>
        </div>
      </div>

      <!-- <ul class="col-start-2 sm:col-start-7 col-span-5 sm:col-span-3 space-y-2">
        <li v-for="menuItem, key in placeholderMenu" :key="key">
          <a href="#" class="text-xs uppercase tracking-widest underline underline-offset-4">{{ menuItem }}</a>
        </li>
      </ul>
      <ul class="col-start-8 sm:col-start-10 col-span-5 sm:col-span-3 space-y-2">
        <li v-for="menuItem, key in placeholderMenu" :key="key">
          <a href="#" class="text-xs uppercase tracking-widest underline underline-offset-4">{{ menuItem }}</a>
        </li>
      </ul> -->
    </div>
  </div>
</template>
