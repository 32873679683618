<script setup lang="ts">
import { onMounted } from 'vue'
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'Sanctuary Layout',
})

const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const hideWidgetOnPageIds: Array<number> = [580]
const showBookingWidget: boolean = !hideWidgetOnPageIds.includes(currentPage.id)

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/sanctuary/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/sanctuary/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/sanctuary/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/sanctuary/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022', links)

const {
  resorts,
  brands,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, ['footerCompanyMenu', 'footerResourcesMenu'])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#024263' })

if (currentResort?.value)
  provide('currentResort', currentResort.value.data)

if (resorts?.value)
  provide('resorts', resorts.value.data)

if (brands?.value)
  provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white sanctuary-theme"
    >
      <div id="app">
        <div id="portal-destination" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              menuPosition: 'right', // right or center
              logoPosition: 'left',
              backgroundClass: 'bg-white',
              paper: false,
              bookNowBackground: 'bg-[#024263]',
              allResorts: true,
              booking: showBookingWidget,
            }"
          />
          <slot />

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :config="{
              crossPromotion: false,
              signUpForm: true,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/sanctuary.scss';
</style>
