<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Jewel Grande Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/jewel-grande/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/jewel-grande/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/jewel-grande/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/jewel-grande/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022', links)

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#30a298' })

if (currentResort?.value)
  provide('currentResort', currentResort.value.data)

if (resorts?.value)
  provide('resorts', resorts.value.data)

if (brands?.value)
  provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white jewelgrande-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: true,
              logoWidth: 200,
              logoPosition: 'left', // left or center
              menuPosition: 'center', // right or center
              backgroundClass: 'bg-white',
              bookNowBackground: 'bg-[#17314c]',
              paper: false,
              booking: true,
            }"
          />
          <slot />

          <PlayaresortsFooter
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            :footer-company-menu="footerCompanyMenu?.data"
            :footer-resources-menu="footerResourcesMenu?.data"
            :footer-terms-menu="footerTermsMenu?.data"
            bg-type="white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/jewelgrande.scss';
</style>
