import { useSiteUtils } from '@voix/composables/useSiteUtils'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getCurrentDomain } = useSiteUtils()
  const domain = getCurrentDomain()

  console.log('[LOG] redirect middleware on', domain)

  if (domain === 'www.jewelresorts.com') {
    console.log('[LOG] at jewel time to redirect', from.path)

    let redirectURL = 'https://www.jewelgrande.com/'

    const redirectMap = {
      '/': 'https://www.jewelgrande.com/',
      '/paradisecove': 'https://www.jewelgrande.com/',
      '/special-offers': 'https://www.jewelgrande.com/specials',
      '/weddings': 'https://www.jewelgrande.com/weddings',
      '/groups': 'https://www.jewelgrande.com/groups',
      '/paradisecove/adult-only-suites': 'https://www.jewelgrande.com/family-suites',
      '/paradisecove/dining-drinks': 'https://www.jewelgrande.com/dining-drinks',
      '/paradisecove/specials': 'https://www.jewelgrande.com/specials',
      '/paradisecove/activities': 'https://www.jewelgrande.com/activities',
      '/paradisecove/activities/spa': 'https://www.jewelgrande.com/grande-spa',
      '/paradisecove/activities/scuba-diving': 'https://www.jewelgrande.com/',
      '/paradisecove/wellness-retreat': 'https://na.spatime.com/jgmb2797/69432059/home',
      '/paradisecove/weddings': 'https://www.jewelgrande.com/weddings',
      '/paradisecove/weddings/packages': 'https://www.jewelgrande.com/weddings',
      '/paradisecove/groups': 'https://www.jewelgrande.com/groups',
      '/paradisecove/groups/where-to-start': 'https://www.jewelgrande.com/groups,,',
      '/paradisecove/groups/leisure-groups': 'https://www.jewelgrande.com/groups/social-groups',
      '/paradisecove/groups/meeting-spaces': 'https://www.jewelgrande.com/groups',
      '/paradisecove/gallery': 'https://www.jewelgrande.com/',
      '/faq': 'https://www.jewelgrande.com/faq',
    }
    if (Object.prototype.hasOwnProperty.call(redirectMap, from.path)) {
      console.log('[LOG] found path in map', redirectMap[from.path])

      redirectURL = redirectMap[from.path]
    }

    console.log('[LOG] redirecting to:', redirectURL)

    return navigateTo(redirectURL, { external: true, redirectCode: 302 })
  }
})
